import http, { Service } from "./common";

const resource = "packageItem";
class PackageItemService extends Service {
  verify_create(data) {
    console.log(data);
    return [];
  }
  async saveItems(data) {
    const response = await http.post(`/${resource}/items`, data);
    return response.data;
  }
  async loadItems(pkgId) {
    let uri = `/${resource}/?pkgId=${pkgId}`;
    const response = await http.get(uri);
    return response.data;
  }

  findByCode(code) {
    return http.get(`/${resource}?code=${code}`);
  }

  findBySender(sender) {
    return http.get(`/${resource}?sender=${sender}`);
  }
}

export default new PackageItemService(resource);
